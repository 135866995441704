import React from "react";
import Head from "next/head";
import _ from "lodash";
import { usePageContext } from "@providers/PageProvider";
import { useRouter } from "next/router";
// import getter from '@services/StrapiService/getter';
import helper from "@utils/helper";
const interpolateContent = (
  content: any,
  { seoData, resolvedUrl }: any
) => {
  let metaPath = (resolvedUrl?.replace(/^\/[a-z]{2}/, '') || '').replace(/^(?!\/)/, '/');  // Remove language prefix if exists and ensure leading slash
  try {
    return _.template(content, {
      interpolate: /{{([\s\S]+?)}}/g,
    })({
      ...seoData,
      metaPath,
    });
  } catch (err) {
    return content;
  }
};
const View = () => {
  const { pageData, resolvedUrl } = usePageContext();
  const metaItems = [
    /* <!-- Schema.org markup for Google+ --> */
    {
      content: "{{metaTitle}}",
    },
    {
      name: "description",
      content: "{{metaDescription}}",
    },
    {
      content: "{{metaImage}}",
    },
    {
      name: "keywords",
      content: "{{metaKeywords}}",
    },
    // Add canonical link
    {
      rel: "canonical",
      href: `${process.env.DOMAIN}{{metaPath}}`,
    },
    // Add alternate language links
    {
      rel: "alternate",
      hrefLang: "en",
      href: `${process.env.DOMAIN}{{metaPath}}`,
    },
    {
      rel: "alternate",
      hrefLang: "vi",
      href: `${process.env.DOMAIN}/vi{{metaPath}}`,
    },
    // <!-- Twitter Card data -->
    {
      name: "twitter:card",
      content: "{{metaType}}",
    },
    {
      name: "twitter:site",
      content: "",
    },
    {
      name: "twitter:title",
      content: "{{metaTitle}}",
    },
    {
      name: "twitter:description",
      content: "{{metaDescription}}",
    },

    {
      name: "twitter:image",
      content: "{{metaImage}}",
    },

    // <!-- Open Graph data -->
    {
      property: "og:title",
      content: "{{metaTitle}}",
    },
    {
      property: "og:type",
      content: "{{metaType}}",
    },
    {
      property: "og:url",
      content: "{{metaUrl}}",
    },
    {
      property: "og:image",
      content: "{{metaImage}}",
    },
    {
      property: "og:description",
      content: "{{metaDescription}}",
    },
    {
      property: "og:site_name",
      content: "",
    },
    {
      property: "og:price:amount",
      content: "",
    },
    {
      property: "og:price:currency",
      content: "",
    },
  ];
  const router = useRouter();
  const seoData = helper.formatMetatags(pageData);
  // console.log({ pageData, seoData });
  return (
    <Head>
      <title>{_.get(seoData, "metaTitle", "")}</title>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": `${process.env.DOMAIN}`,
            "name": [
              {
                "@language": "vi",
                "@value": "Khách sạn Amanaki - Khách sạn Boutique tại Sài Gòn | Việt Nam"
              },
              {
                "@language": "en",
                "@value": "Amanaki Hotel - Boutique Hotel in Ho Chi Minh City | Vietnam"
              }
            ],
            "description": [
              {
                "@language": "vi",
                "@value": "Khách sạn boutique tại Thành phố Hồ Chí Minh với nguồn cảm hứng từ hạnh phúc, cân bằng và bền vững. Hãy đặt kỳ nghỉ của bạn tại Amanaki, trải nghiệm Sài Gòn, kết nối trở lại và khám phá niềm hạnh phúc dựa trên tâm - thân - trí."
              },
              {
                "@language": "en",
                "@value": "A boutique hotel in Ho Chi Minh City inspired by happiness, balance, and sustainability. Book your stay at Amanaki to experience Saigon, reconnect and discover happiness through a holistic approach to mind, body, spirit."
              }
            ],
            "publisher": {
              "@type": "Organization",
              "name": [
                {
                  "@language": "vi",
                  "@value": "Khách sạn Amanaki"
                },
                {
                  "@language": "en",
                  "@value": "Amanaki Hotel"
                }
              ],
              "logo": {
                "@type": "ImageObject",
                "url": `${process.env.DOMAIN}/amanaki-logo.png`
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `${process.env.DOMAIN}`
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": `${process.env.DOMAIN}/search?q={amanaki}`,
              "query-input": "required name=amanaki"
            },
            "inLanguage": ["vi", "en"]
          })
        }}
      />
      {/* <script
        defer={true}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KRC5WFT');`,
        }}
      ></script> */}

      <link rel="icon" href="/favicon.svg" />
      {metaItems.map((item, index) => {
        let { content, href, ...res } = item;
        if (content) {
          content = interpolateContent(content, { seoData, router, resolvedUrl });
          if (!content) return null;
          return <meta key={`${index}`} {...res} content={content} />;
        }
        if (href) {
          href = interpolateContent(href, { seoData, router, resolvedUrl });
          if (!href) return null;
          return <link key={`${index}`} {...res} href={href} />;
        }
        return null;
      })}
    </Head>
  );
};
export default View;
